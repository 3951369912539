import React, { useEffect } from 'react';
import "./HeaderV2.css";
import { imgVersion, handleDownload, isMac } from '../common/Utils';

export const HeaderV2 = ({ macDownloadLink, windowsDownloadLink }) => {
  // pc浏览器默认滚动条定位
  useEffect(() => {
    if (window.innerWidth <= 768) {
      return;
    }
    const lastPosition = sessionStorage.getItem("lastScrollPosition");
    if (!lastPosition) {
      const windowHeight = window.innerHeight;
      const firstScreenHeight = 830;
      if (windowHeight  < firstScreenHeight) {
        const firstScreenMiddleY = 450; //   (830 - 70) / 2 + 70
        const beautyOffsetY = -30;
        const positionY = firstScreenMiddleY - (windowHeight / 2) + 70 + beautyOffsetY;
        window.scrollTo(0, positionY);
      }
    }
    const handleBeforeUnload = () => {
      sessionStorage.setItem("lastScrollPosition", window.scrollY);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <header className="header-v2">
      <div className="container">
        <div className="header-v2-left">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/reward_pc.png?v=${imgVersion}`}
            alt="注册即送免费时长"
            className="header-v2-pc-reward-banner"
          />
          <h1>Offer鸡</h1>
          <p>AI面试演讲提词器<br />轻松拿Offer</p>
          <button
            className="download-button header-v2-download-button header-v2-web-button"
            id="main-web-button"
            onClick={() => {
              window.location.href = 'https://app.offerchick.cn';
            }}
          >
            使用网页版
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/goto_arrow.png?v=${imgVersion}`}
            />
          </button>
          <button
            className="download-button header-v2-download-button"
            id="main-download-button"
            onClick={() => handleDownload(isMac ? macDownloadLink : windowsDownloadLink)}
          >
            下载专业版
          </button>
          <p className="header-v2-download-button-desc">Mac 13.0 | Win10/11 64位</p>
        </div>

        <img
          src={`${process.env.PUBLIC_URL}/assets/images/header_wing.png?v=${imgVersion}`}
          alt="header-wing"
          className="header-v2-wing"
        />

        <div className="header-v2-right">
          <div className="header-v2-video-container">
            <img 
              src={`${process.env.PUBLIC_URL}/assets/images/video_bg.png?v=${imgVersion}`}
              alt="video-bg"
              className="header-v2-video-bg"
            />
            <div className="header-v2-video-player">
              <iframe
                src="https://player.bilibili.com/player.html?bvid=BV1euSMYCEMJ&quality=80"
                allowFullScreen
                title="header-v2-video-player"
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}